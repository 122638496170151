.receipt {
  min-height: 100vh;
  width: 100%;
}

.receipt_backdrop {
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 100%;
  background-color: transparent;
  z-index: 100;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.receipt_backdrop_active {
  height: 100vh;
}

.receipt_box {
  margin: 15px auto;
  width: fit-content;
  min-width: 360px;
  padding: 20px;
  border-radius: 10px;
  background: #fff;
}
.receipt_form-elem {
  display: flex;
  flex-direction: column;
  position: relative;
}
.receipt_form-elem label {
  text-align: start;
  color: var(--font-1);
  font-size: 0.9rem;
  letter-spacing: 1px;
  font-weight: 500;
  margin-top: 10px;
  padding: 0 15px;
}
.receipt_form-elem input {
  background: #fff;
  min-width: 250px;
  border: none;
  outline: none;
  padding: 15px;
  box-shadow: -1px 2px 5px rgb(0 0 0 / 10%);
  border-radius: 5px;
  font-size: 1rem;
  color: #000;
}

.receipt_calendar {
  position: absolute;
  left: -15px;
  top: 100%;
  z-index: 200;
  overflow: hidden;
  height: 0;
}
.receipt_calendar_active {
  height: fit-content;
}
