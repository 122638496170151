@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&family=Rubik:wght@500;700&display=swap");

:root {
  --primary-color: #7e5ea8;
  --secondary-color: #fec66c;
}

.App {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #6d6687;
}
