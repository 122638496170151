.forgot-password-form {
  padding: 10px;
  text-align: start;
}
.forgot-password-form label {
  text-align: start;
  color: var(--font-1);
  font-size: 0.9rem;
  letter-spacing: 1px;
  font-weight: 500;
  padding: 0 15px;
}
.forgot-password-form input {
  background: #fff;
  min-width: 250px;
  width: 100%;
  border: none;
  outline: none;
  padding: 15px;
  box-shadow: -1px 2px 5px rgb(0 0 0 / 10%);
  border-radius: 5px;
  font-size: 1rem;
  color: var(--font-2);
}
.forgot-password-form input:focus,
.forgot-password-form input:focus {
  box-shadow: -2px 7px 12px rgb(0 0 0 / 20%);
}
.forgot-password-form input::placeholder,
.forgot-password-form input::placeholder {
  color: #8f9397;
}
