.profile {
  position: relative;
  min-height: 100vh;
}

.profile_backdrop-box {
  position: absolute;
  top: -110%;
  left: 0;
  height: 100%;
  width: 100%;
  transition: 300ms;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}
.profile_backdrop-box_active {
  background-color: rgba(0 0 0/14%);
  top: 0;
}
.profile_backdrop-box_update-password,
.profile_backdrop-box_update-profile {
  background-color: #fff;
  border-radius: 5px;
  padding: 30px 20px;
  padding-top: 10px;
  min-width: 310px;
  max-width: 360px;
}
.profile_backdrop-box_update-profile {
  max-width: 800px;
}
.update-password_pass-box {
  position: absolute;
  left: 0;
  top: 103%;
  background-color: #fff;
  width: 100%;
  height: 0;
  padding: 0;
  overflow: hidden;
  transition: 150ms;
  text-align: center;
}

.update-password_pass-box_active {
  min-width: 150px;
  height: fit-content;
  padding: 10px;
  box-shadow: 1px -1px 10px rgba(0 0 0 /14%);
  padding-right: 20px;
  z-index: 100;
}
.update-password_pass-box li {
  display: flex;
}
.update-password_pass-box p {
  text-align: start;
  margin: 0 5px;
}
.update-password_form-elem,
.update-profile_form-elem {
  display: flex;
  flex-direction: column;
}
.update-password_form-elem label,
.update-profile_form-elem label {
  text-align: start;
  color: var(--font-1);
  font-size: 0.9rem;
  letter-spacing: 1px;
  font-weight: 500;
  margin-top: 10px;
  padding: 0 15px;
}
.update-password_form-elem input,
.update-profile_form-elem input {
  background: #fff;
  min-width: 250px;
  border: none;
  outline: none;
  padding: 15px;
  box-shadow: -1px 2px 5px rgb(0 0 0 / 10%);
  border-radius: 5px;
  font-size: 1rem;
  color: #000;
}
.update-password_form-elem select,
.update-profile_form-elem select {
  background: #fff;
  min-width: 250px;
  border: none;
  outline: none;
  padding: 15px;
  box-shadow: -1px 2px 5px rgb(0 0 0 / 10%);
  border-radius: 5px;
  font-size: 1rem;
  color: #000;
}
.update-password_form-elem .password-input {
  box-shadow: none;
}
.update-password_form-elem input:focus,
.update-profile_form-elem input:focus {
  box-shadow: -2px 7px 12px rgb(0 0 0 / 20%);
}
.update-password_form-elem .password-input:focus {
  box-shadow: none;
}

.update-password_form-elem input::placeholder {
  color: #8f9397;
}
