.buttonComp {
  display: block;
  width: fit-content;
  margin: 10px auto;
  padding: 10px 40px;
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 35px;
  font-size: 14px;
  font-weight: 500;
  -webkit-transition-duration: 400ms;
  transition-duration: 400ms;
  outline: none !important;
  border: none;
  user-select: none;
  cursor: pointer;
}

.buttonComp:hover {
  background-color: #2e2a5a;
  color: #fec66c;
}

.buttonComp:active {
  transform: translateY(3px);
}

.buttonComp-raised {
  -webkit-box-shadow: 0 20px 30px 0 rgb(204 37 107 / 20%);
  box-shadow: 0 20px 30px 0 rgb(204 37 107 / 20%);
}
.buttonComp-raised:hover {
  -webkit-box-shadow: 0 20px 30px 0 rgb(67 37 204 / 20%);
  box-shadow: 0 20px 30px 0 rgb(67 37 204 / 20%);
}

.buttonComp-disabled {
  background: darkgray !important;
}
.buttonComp-disabled:hover {
  color: #fff;
}
