@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&family=Rubik:wght@500;700&display=swap);
.preloader_container {
  height: 100vh;
  width: 100%;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 150px;
  height: 140px;
  display: flex;
  justify-content: center;
  margin: auto; */
}

.preloader:before,
.preloader:after,
.preloader {
  border-style: solid;
  border-width: 50px 40px 0;
  border-radius: 40px;
  transform-origin: bottom center;
  animation-name: rotate;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  border-color: transparent;
}

.preloader {
  width: 0;
  height: 0;
  border-top-color: #2e2a5a;
  position: relative;
  animation-duration: 1s;
}
.preloader:before,
.preloader:after {
  content: "";
  display: block;
  position: absolute;
  top: -50px;
  left: -40px;
}
.preloader:after {
  border-top-color: var(--secondary-color);
  animation-duration: 2s;
}
.preloader:before {
  border-top-color: #fff;
  animation-duration: 4s;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* .preloader_container {
  height: 100vh;
  width: 100%;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.preloader_container section {
  perspective: 200px;
}

.preloader {
  width: 80px;
  height: 80px;
  background: #fff;
  border-radius: 10px;
  transition: all 5s;
  animation: loader 1.2s linear infinite;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotateX(0deg) rotateY(180deg);
  }
  100% {
    transform: rotateX(180deg) rotateY(180deg);
  }
} */

.buttonComp {
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 10px auto;
  padding: 10px 40px;
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 35px;
  font-size: 14px;
  font-weight: 500;
  transition-duration: 400ms;
  outline: none !important;
  border: none;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}

.buttonComp:hover {
  background-color: #2e2a5a;
  color: #fec66c;
}

.buttonComp:active {
  transform: translateY(3px);
}

.buttonComp-raised {
  box-shadow: 0 20px 30px 0 rgb(204 37 107 / 20%);
}
.buttonComp-raised:hover {
  box-shadow: 0 20px 30px 0 rgb(67 37 204 / 20%);
}

.buttonComp-disabled {
  background: darkgray !important;
}
.buttonComp-disabled:hover {
  color: #fff;
}

.forgot-password-form {
  padding: 10px;
  text-align: start;
}
.forgot-password-form label {
  text-align: start;
  color: var(--font-1);
  font-size: 0.9rem;
  letter-spacing: 1px;
  font-weight: 500;
  padding: 0 15px;
}
.forgot-password-form input {
  background: #fff;
  min-width: 250px;
  width: 100%;
  border: none;
  outline: none;
  padding: 15px;
  box-shadow: -1px 2px 5px rgb(0 0 0 / 10%);
  border-radius: 5px;
  font-size: 1rem;
  color: var(--font-2);
}
.forgot-password-form input:focus,
.forgot-password-form input:focus {
  box-shadow: -2px 7px 12px rgb(0 0 0 / 20%);
}
.forgot-password-form input::-webkit-input-placeholder, .forgot-password-form input::-webkit-input-placeholder {
  color: #8f9397;
}
.forgot-password-form input:-ms-input-placeholder, .forgot-password-form input:-ms-input-placeholder {
  color: #8f9397;
}
.forgot-password-form input::placeholder,
.forgot-password-form input::placeholder {
  color: #8f9397;
}

:root {
  --font-1: #6c757d;
  --font-2: #5a5e61;
}
.login,
.signup,
.e-login,
.e-signup {
  padding: 20px 40px;
  background-color: #fff;
  text-align: center;
  margin: 10px;
}
.login_head,
.signup_head {
  font-size: 2.3rem;
  color: #44415a;
  text-align: center;
  margin-bottom: 10px;
}
.login_sub-head,
.signup_sub-head {
  color: #6c757d;
  color: var(--font-1);
  font-size: 1rem;
  margin-bottom: 20px;
  text-transform: capitalize;
  letter-spacing: 1px;
}
.login_form-elem,
.signup_form-elem {
  display: flex;
  flex-direction: column;
}
.login_form-elem label,
.signup_form-elem label {
  text-align: start;
  color: #6c757d;
  color: var(--font-1);
  font-size: 0.9rem;
  letter-spacing: 1px;
  font-weight: 500;
  margin-top: 10px;
  padding: 0 15px;
}
.login_form-elem input,
.signup_form-elem input {
  background: #fff;
  min-width: 250px;
  border: none;
  outline: none;
  padding: 15px;
  box-shadow: -1px 2px 5px rgb(0 0 0 / 10%);
  border-radius: 5px;
  font-size: 1rem;
  color: #5a5e61;
  color: var(--font-2);
}
.signup_form-elem .password-input,
.login_form-elem .password-input {
  box-shadow: none;
}
.login_form-elem input:focus,
.signup_form-elem input:focus {
  box-shadow: -2px 7px 12px rgb(0 0 0 / 20%);
}
.signup_form-elem .password-input:focus,
.login_form-elem .password-input:focus {
  box-shadow: none;
}

.login_form-elem input::-webkit-input-placeholder, .signup_form-elem input::-webkit-input-placeholder {
  color: #8f9397;
}

.login_form-elem input:-ms-input-placeholder, .signup_form-elem input:-ms-input-placeholder {
  color: #8f9397;
}

.login_form-elem input::placeholder,
.signup_form-elem input::placeholder {
  color: #8f9397;
}
.login_form-elem select,
.signup_form-elem select {
  background: #fff;
  min-width: 250px;
  border: none;
  outline: none;
  padding: 15px;
  box-shadow: -1px 2px 5px rgb(0 0 0 / 10%);
  border-radius: 5px;
  font-size: 1rem;
  color: #5a5e61;
  color: var(--font-2);
}
.login_form-elem select:focus,
.signup_form-elem select:focus {
  box-shadow: -2px 7px 12px rgb(0 0 0 / 20%);
}

.login_special-text,
.signup_special-text {
  cursor: pointer;
  transition: 200ms;
  color: #7e5ea7;
}

.login_special-text:hover,
.signup_special-text:hover {
  color: #241e34;
  transform: scale(1.2);
}

.e-signup_container {
  align-items: center;
}
.e-signup_text-content {
  max-width: 600px;
  padding: 0 10px;
  color: #fff;
}
.e-signup_text-content h1 {
  text-align: center;
  font-size: 2.3rem;
}
.e-signup_text-content ul {
  padding-left: 20px;
}
.e-signup_text-content ul li {
  list-style-type: square;
  font-size: 1rem;
  margin: 10px;
}
.signup_pass-box {
  position: absolute;
  left: 0;
  top: 103%;
  background-color: #fff;
  width: 100%;
  height: 0;
  padding: 0;
  overflow: hidden;
  transition: 150ms;
  text-align: center;
}

.signup_pass-box_active {
  min-width: 150px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 10px;
  box-shadow: 1px -1px 10px rgba(0 0 0 /14%);
  padding-right: 20px;
  z-index: 100;
}
.signup_pass-box li {
  display: flex;
}
.signup_pass-box p {
  margin: 0 5px;
}

.field-error-msg {
  font-size: 0.75rem;
  font-weight: bold;
  color: #ff4500;
  text-align: start;
  margin-left: 10px;
  width: 100%;
}

@media (max-width: 700px) {
  .e-signup_container {
    flex-direction: column;
  }
}

.auth {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background: #2e2a5a;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo{
  position: absolute;
  top: 10px;
  left: 30px;
}
@media screen and (max-width: 992px) {
  .ilustration, .logo{
    display: none;
  }
}
.navbar_container {
  width: 100%;
  height: 84px;
  background-color: #2e2a5a;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  position: relative;
}
.navbar {
  background-color: #2e2a5a;
  width: 100%;
  position: fixed;
  z-index: 800;
  padding: 0;
}

.navbar_backdrop {
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.navbar_backdrop_active {
  z-index: 100;
  height: 100vh;
}

.navbar_inner {
  margin: 0 auto;
  max-width: 1140px;
  min-height: 84px;
  width: 100%;
  display: flex;
  padding: 0px 20px;
  justify-content: space-between;
  align-items: center;
}
.navbar_logo {
  display: flex;
  align-items: baseline;
}

.navbar_logo img {
  max-height: 60px;
  height: 100%;
}

.navbar_user {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  color: #fff;
  padding: 10px;
}
.navbar_user_body {
  display: flex;
  cursor: pointer;
  align-items: center;
}
.navbar_user p {
  margin: 0;
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: capitalize;
  color: #fff;
  text-align: center;
}
.navbar_user p span {
  font-size: 0.9rem;
}
.navbar_user small {
  font-size: 0.8rem;
  font-weight: bold;
  color: lightgray;
}
.navbar_box {
  position: absolute;
  right: 0;
  top: 105%;
  background-color: #fff;
  color: #000;
  box-shadow: 1px -1px 10px rgba(128, 128, 128, 0.596);
  height: 0;
  width: 30%;
  overflow: hidden;
  transition: 100ms;
  z-index: 2000;
}
.navbar_box_active {
  min-width: 150px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 20px;
}

.navbar_box ul li {
  list-style: none;
  margin: 10px;
  cursor: pointer;
}

@media (max-width: 500px) {
  .navbar_logo {
    font-size: 1.3rem;
  }
  .navbar_logo img {
    max-height: 50px;
  }
}

.dashboard_body {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 800px) {
  .dashboard_body {
    flex-direction: column;
    justify-content: center;
  }
}

.dashboard_sidebar-left_container,
.dashboard_sidebar-right_container {
  min-width: 250px;
  min-height: 400px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 10px;
}
.dashboard_sidebar-left,
.dashboard_sidebar-right {
  position: fixed;
}

.dashboard_sidebar-left_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard_sidebar-left_box {
  border-radius: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  width: 250px;
  padding: 20px 10px;
  background-color: #fff;
  box-shadow: 1px -1px 12px lightgray;
  margin: 10px;
  text-align: center;
}
.dashboard_sidebar-left_box .title {
  color: #7e5ea7;
  font-weight: bolder;
  font-size: 1.8rem;
  margin-bottom: 5px;
}
.dashboard_sidebar-left_box .sub-title {
  min-width: 180px;
  font-weight: 200;
  width: 100%;
  padding: 0;
}

.dashboard_sidebar-right_container {
  min-width: 290px;
}

.dashboard_sidebar-right_box {
  background-color: #7e5ea7;
  padding: 30px 25px;
  border-radius: 5px;
  margin: 10px;
}

.dashboard_sidebar-right_box_item {
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 8px;
  align-items: center;
}
.dashboard_sidebar-right_box_item label {
  margin: 7px 0;
  color: #fff;
  font-size: 1rem;
  white-space: nowrap;
  font-weight: 200;
}
.dashboard_sidebar-right_box_item p {
  justify-self: flex-end;
  font-weight: bold;
  margin: 0;
  color: #fff;
}
.dashboard_sidebar-right_box button {
  background-color: #fec66c;
  color: #fff;
}
.dashboard_sidebar-right_box button:hover {
  background-color: #2e2a5a;
  color: #fec66c;
}

@media (max-width: 800px) {
  .dashboard_sidebar-left_container,
  .dashboard_sidebar-right_container {
    min-height: 150px;
  }
  .dashboard_sidebar-left,
  .dashboard_sidebar-right {
    position: unset;
    min-height: 150px;
  }
}

.dashboard_main {
  background-color: #f8f9fa;
  min-height: 500px;
  height: 100%;
  width: 100%;
  padding: 20px;
  justify-content: space-around;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}

.dashboard_main_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  width: 130px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  margin: 0 auto;
}

.dashboard_main_box .title {
  text-align: center;
  font-size: 1rem;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-weight: 600;
  color: #6d6687;
}

.directory_head_container,
.directory_head {
  background-color: var(--secondary-color);
}

.directory_head {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.directory_head_left h1 {
  margin: 0;
  margin-top: 10px;
  font-size: 30px;
}
.directory_head_left p {
  font-size: 0.9rem;
  margin: 0;
}

.directory_head_right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.react-calendar__tile--now {
  background: var(--secondary-color) !important;
}
.react-calendar__tile--hasActive {
  background: var(--primary-color) !important;
  color: #fff !important;
}

.react_spinner_container {
  width: 50px;
  height: 40px;
  display: flex;
  justify-content: center;
  margin: auto;
}

.react_spinner:before,
.react_spinner:after,
.react_spinner {
  border-style: solid;
  border-width: 20px 10px 0;
  transform-origin: bottom center;
  animation-name: rotate;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  border-color: transparent;
}

.react_spinner {
  width: 0;
  height: 0;
  border-top-color: var(--primary-color);
  position: relative;
  animation-duration: 1s;
}
.react_spinner:before,
.react_spinner:after {
  content: "";
  display: block;
  position: absolute;
  top: -20px;
  left: -10px;
}
.react_spinner:after {
  border-top-color: var(--secondary-color);
  animation-duration: 2s;
}
.react_spinner:before {
  border-top-color: var(--primary-color);
  animation-duration: 4s;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.directory_table {
  margin: 20px auto;
  max-width: 1110px;
  background-color: #fff;
  padding: 4px;
  padding-top: 10px;
  box-shadow: 1px 0 10px lightgray;
}
.directory_upload {
  margin-top: 100px;
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  padding-top: 3px;
  min-width: 300px;
  min-height: 300px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.directory_upload_backdrop {
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 100%;
  background-color: rgb(0 0 0 / 50%);
  z-index: 1000;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.directory_upload_backdrop_active {
  height: 100%;
}

.directory_table_backdrop {
  position: absolute;
  top: 0px;
  left: 0;
  height: 0%;
  width: 100%;
  z-index: 10;
  background-color: transparent;
}
.directory_table_backdrop_active {
  height: 100vh;
}

.directory_table_topbar {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.directory_table_topbar_item {
  display: flex;
  align-items: center;
  margin: 0 5px;
}

.directory_table_topbar_item p {
  margin: 0;
  font-size: 0.9rem;
}
.directory_table_topbar_item p ~ span {
  margin: 3px;
  font-weight: bold;
  color: var(--primary-color);
  font-size: 1rem;
}

.directory_table_topbar .custom-column {
  position: relative;
}
.directory_table_topbar .custom-column .custom-column-box {
  position: absolute;
  right: 4px;
  top: 101%;
  background-color: #fff;
  width: 100px;
  height: 0;
  padding: 0;
  overflow: hidden;
  transition: 150ms;
}

.directory_table_topbar .custom-column .custom-column-box_active {
  min-width: 200px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  box-shadow: 1px -1px 10px rgba(128, 128, 128, 0.596);
  padding-right: 20px;
  z-index: 200;
}

.directory_table_topbar .custom-column .custom-column-box div {
  display: flex;
  align-items: center;
}
.directory_table_topbar .custom-column .custom-column-box div p {
  /* white-space: nowrap; */
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

/* .directory_table_topbar button {
  display: block;
  width: fit-content;
  margin-left: auto;
  padding: 6px 30px;
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 35px;
  font-size: 14px;
  font-weight: 500;
  -webkit-transition-duration: 400ms;
  transition-duration: 400ms;
  outline: none;
  border: none;
  user-select: none;
}

.directory_table_topbar button:hover {
  background-color: var(--secondary-color);
  color: var(--primary-color);
}

.directory_table_topbar button:active {
  transform: translateY(3px);
} */

.directory_table_navbar {
  padding: 10px 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.directory_table_navbar .select_count {
  padding: 3px 6px;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  font-weight: bold;
  margin-right: 10px;
  margin-left: 12px;
}

.directory_table_navbar .search {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  box-shadow: 1px 1px 10px rgba(211, 211, 211, 0.685);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.directory_table_navbar .search input {
  width: 200px;
  border: none;
  outline: none;
  border-right: 1px solid var(--primary-color);
}
.directory_table_navbar .table_navbar_item {
  margin: 0 15px;
  display: flex;
  align-items: center;
  cursor: default;
  position: relative;
}
.directory_table_navbar .table_navbar_item .import-box {
  position: absolute;
  right: 4px;
  top: 101%;
  background-color: #fff;
  width: 100px;
  height: 0;
  padding: 0;
  overflow: hidden;
  transition: 150ms;
  text-align: center;
  z-index: 200;
}
.directory_table_navbar .table_navbar_item .import-box_active {
  min-width: 200px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 25px 10px;
  box-shadow: 1px -1px 10px rgba(128, 128, 128, 0.596);
  padding-right: 20px;
}
.directory_table_navbar .table_navbar_item .import-box button {
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  margin: auto;
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  transition-duration: 400ms;
  outline: none;
  border: none;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}
.directory_table_navbar .table_navbar_item .import-box button:hover {
  background-color: var(--secondary-color);
  color: var(--primary-color);
}
.directory_table_navbar .table_navbar_item .import-box p {
  font-size: 0.8rem;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  line-height: normal;
  margin-top: 20px;
  cursor: pointer;
}
.directory_table_navbar .table_navbar_item p {
  margin: 0;
  margin-left: 5px;
}

@media (max-width: 500px) {
  .directory_table_navbar .table_navbar_item {
    margin: 0 5px;
  }
  .directory_table_navbar .table_navbar_item > p {
    height: 0px;
    width: 0px;
    overflow: hidden;
  }

  .directory_table_navbar .select_count {
    margin: 0 4px;
  }
  .directory_table_navbar .search input {
    width: 160px;
  }
  .directory_table_topbar button {
    margin: auto !important;
  }
}

.directory {
  background-color: #f5f5ff;
  position: relative;
}

.profile {
  position: relative;
  min-height: 100vh;
}

.profile_backdrop-box {
  position: absolute;
  top: -110%;
  left: 0;
  height: 100%;
  width: 100%;
  transition: 300ms;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}
.profile_backdrop-box_active {
  background-color: rgba(0 0 0/14%);
  top: 0;
}
.profile_backdrop-box_update-password,
.profile_backdrop-box_update-profile {
  background-color: #fff;
  border-radius: 5px;
  padding: 30px 20px;
  padding-top: 10px;
  min-width: 310px;
  max-width: 360px;
}
.profile_backdrop-box_update-profile {
  max-width: 800px;
}
.update-password_pass-box {
  position: absolute;
  left: 0;
  top: 103%;
  background-color: #fff;
  width: 100%;
  height: 0;
  padding: 0;
  overflow: hidden;
  transition: 150ms;
  text-align: center;
}

.update-password_pass-box_active {
  min-width: 150px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 10px;
  box-shadow: 1px -1px 10px rgba(0 0 0 /14%);
  padding-right: 20px;
  z-index: 100;
}
.update-password_pass-box li {
  display: flex;
}
.update-password_pass-box p {
  text-align: start;
  margin: 0 5px;
}
.update-password_form-elem,
.update-profile_form-elem {
  display: flex;
  flex-direction: column;
}
.update-password_form-elem label,
.update-profile_form-elem label {
  text-align: start;
  color: var(--font-1);
  font-size: 0.9rem;
  letter-spacing: 1px;
  font-weight: 500;
  margin-top: 10px;
  padding: 0 15px;
}
.update-password_form-elem input,
.update-profile_form-elem input {
  background: #fff;
  min-width: 250px;
  border: none;
  outline: none;
  padding: 15px;
  box-shadow: -1px 2px 5px rgb(0 0 0 / 10%);
  border-radius: 5px;
  font-size: 1rem;
  color: #000;
}
.update-password_form-elem select,
.update-profile_form-elem select {
  background: #fff;
  min-width: 250px;
  border: none;
  outline: none;
  padding: 15px;
  box-shadow: -1px 2px 5px rgb(0 0 0 / 10%);
  border-radius: 5px;
  font-size: 1rem;
  color: #000;
}
.update-password_form-elem .password-input {
  box-shadow: none;
}
.update-password_form-elem input:focus,
.update-profile_form-elem input:focus {
  box-shadow: -2px 7px 12px rgb(0 0 0 / 20%);
}
.update-password_form-elem .password-input:focus {
  box-shadow: none;
}

.update-password_form-elem input::-webkit-input-placeholder {
  color: #8f9397;
}

.update-password_form-elem input:-ms-input-placeholder {
  color: #8f9397;
}

.update-password_form-elem input::placeholder {
  color: #8f9397;
}

.transaction_table {
  margin: 20px auto;
  max-width: 1110px;
  background-color: #fff;
  padding: 4px;
  padding-top: 10px;
  box-shadow: 1px 0 10px lightgray;
}
.transaction_backdrop {
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 100%;
  background-color: transparent;
  z-index: 100;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.transaction_backdrop_active {
  height: 100vh;
}
.transaction_table_topbar {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.transaction_table_topbar_item {
  display: flex;
  align-items: center;
  margin: 0 5px;
}

.transaction_table_topbar_item p {
  margin: 0;
  font-size: 0.9rem;
}
.transaction_table_topbar_item p ~ span {
  margin: 3px;
  font-weight: bold;
  color: var(--primary-color);
  font-size: 1rem;
}

.transaction_table_topbar .custom-column {
  position: relative;
}
.transaction_table_topbar .custom-column .custom-column-box {
  position: absolute;
  left: 1px;
  top: 101%;
  background-color: #fff;
  width: 100px;
  height: 0;
  padding: 0;
  overflow: hidden;
  transition: 150ms;
}

.transaction_table_topbar .custom-column .custom-column-box_active {
  min-width: 200px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  box-shadow: 1px -1px 10px rgba(128, 128, 128, 0.596);
  padding-right: 20px;
  z-index: 200;
}

.transaction_table_topbar .custom-column .custom-column-box div {
  display: flex;
  align-items: center;
}
.transaction_table_topbar .custom-column .custom-column-box div p {
  /* white-space: nowrap; */
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.transaction_table_navbar {
  padding: 10px 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.transaction_table_navbar .select_count {
  padding: 3px 6px;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  font-weight: bold;
  margin-right: 10px;
  margin-left: 12px;
}

.transaction_table_navbar .search {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  box-shadow: 1px 1px 10px rgba(211, 211, 211, 0.685);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.transaction_table_navbar .search input {
  width: 200px;
  border: none;
  outline: none;
  border-right: 1px solid var(--primary-color);
}
.transaction_table_navbar .table_navbar_item {
  margin: 0 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.transaction_table_navbar .table_navbar_item p {
  margin: 0;
  margin-left: 5px;
}

@media (max-width: 500px) {
  .transaction_table_navbar .table_navbar_item {
    margin: 0 5px;
  }
  .transaction_table_navbar .table_navbar_item {
    height: 33px;
    width: 33px;
    overflow: hidden;
  }

  .transaction_table_navbar .select_count {
    margin: 0 4px;
  }
  .transaction_table_navbar .search input {
    width: 160px;
  }
}

.transaction_head_container,
.transaction_head {
  background-color: var(--secondary-color);
  position: relative;
}
.transaction_head_backdrop {
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 100%;
  background-color: transparent;
  z-index: 100;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.transaction_head_backdrop_active {
  height: 86vh;
}
.transaction_head {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.transaction_head_left h1 {
  margin: 0;
  margin-top: 10px;
  font-size: 30px;
}

.transaction_head_right {
  display: flex;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  position: relative;
  padding: 3px 20px;
  min-width: 160px;
}

.transaction_head_right h3 {
  margin: 0;
}
.transaction_head_right p {
  margin: 0;
}

.transaction_head_calendar {
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: 200;
  overflow: hidden;
  height: 0;
}
.transaction_head_calendar_active {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

@media (max-width: 600px) {
  .transaction_head_right {
    margin-right: 5px;
  }
}


.receipt {
  min-height: 100vh;
  width: 100%;
}

.receipt_backdrop {
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 100%;
  background-color: transparent;
  z-index: 100;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.receipt_backdrop_active {
  height: 100vh;
}

.receipt_box {
  margin: 15px auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 360px;
  padding: 20px;
  border-radius: 10px;
  background: #fff;
}
.receipt_form-elem {
  display: flex;
  flex-direction: column;
  position: relative;
}
.receipt_form-elem label {
  text-align: start;
  color: var(--font-1);
  font-size: 0.9rem;
  letter-spacing: 1px;
  font-weight: 500;
  margin-top: 10px;
  padding: 0 15px;
}
.receipt_form-elem input {
  background: #fff;
  min-width: 250px;
  border: none;
  outline: none;
  padding: 15px;
  box-shadow: -1px 2px 5px rgb(0 0 0 / 10%);
  border-radius: 5px;
  font-size: 1rem;
  color: #000;
}

.receipt_calendar {
  position: absolute;
  left: -15px;
  top: 100%;
  z-index: 200;
  overflow: hidden;
  height: 0;
}
.receipt_calendar_active {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.verify-doc::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-color: rgba(0 0 0 / 15%);
  z-index: -1;
}

.verify-doc {
  position: absolute;
  top: 0;
  left: 0;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  text-align: center;
}

.verify-doc_inner {
  margin: 30px auto;
  background-color: #fff;
  padding: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.form-elem {
  display: flex;
  flex-direction: column;
}

.form-elem label {
  text-align: start;
  color: darkslategrey;
  font-size: 0.9rem;
  letter-spacing: 1px;
  font-weight: 500;
  margin-top: 10px;
  padding: 0 15px;
}
.form-elem input {
  background: #fff;
  min-width: 250px;
  max-width: 400px;
  border: none;
  outline: none;
  padding: 15px;
  box-shadow: -1px 2px 5px rgb(0 0 0 / 10%);
  border-radius: 5px;
  font-size: 1rem;
  color: #000;
}
.form-elem input::-webkit-input-placeholder {
  color: #8f9397;
}
.form-elem input:-ms-input-placeholder {
  color: #8f9397;
}
.form-elem input::placeholder {
  color: #8f9397;
}
.form-elem input:focus {
  box-shadow: -2px 7px 12px rgb(0 0 0 / 20%);
}

@media (max-width: 600px) {
  .verify-doc_inner {
    padding: 15px;
    margin: 20px 10px;
  }
}

.red-star {
  color: red;
  font-size: 1rem;
}

:root {
  --primary-color: #7e5ea8;
  --secondary-color: #fec66c;
}

.App {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #6d6687;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

