.verify-doc::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-color: rgba(0 0 0 / 15%);
  z-index: -1;
}

.verify-doc {
  position: absolute;
  top: 0;
  left: 0;
  min-height: fit-content;
  height: fit-content;
  min-height: 100%;
  width: 100%;
  z-index: 1000;
  backdrop-filter: blur(8px);
  text-align: center;
}

.verify-doc_inner {
  margin: 30px auto;
  background-color: #fff;
  padding: 20px;
  width: fit-content;
}

.form-elem {
  display: flex;
  flex-direction: column;
}

.form-elem label {
  text-align: start;
  color: darkslategrey;
  font-size: 0.9rem;
  letter-spacing: 1px;
  font-weight: 500;
  margin-top: 10px;
  padding: 0 15px;
}
.form-elem input {
  background: #fff;
  min-width: 250px;
  max-width: 400px;
  border: none;
  outline: none;
  padding: 15px;
  box-shadow: -1px 2px 5px rgb(0 0 0 / 10%);
  border-radius: 5px;
  font-size: 1rem;
  color: #000;
}
.form-elem input::placeholder {
  color: #8f9397;
}
.form-elem input:focus {
  box-shadow: -2px 7px 12px rgb(0 0 0 / 20%);
}

@media (max-width: 600px) {
  .verify-doc_inner {
    padding: 15px;
    margin: 20px 10px;
  }
}

.red-star {
  color: red;
  font-size: 1rem;
}
