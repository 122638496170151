.directory_head_container,
.directory_head {
  background-color: var(--secondary-color);
}

.directory_head {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.directory_head_left h1 {
  margin: 0;
  margin-top: 10px;
  font-size: 30px;
}
.directory_head_left p {
  font-size: 0.9rem;
  margin: 0;
}

.directory_head_right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.react-calendar__tile--now {
  background: var(--secondary-color) !important;
}
.react-calendar__tile--hasActive {
  background: var(--primary-color) !important;
  color: #fff !important;
}
