.dashboard_main {
  background-color: #f8f9fa;
  min-height: 500px;
  height: 100%;
  width: 100%;
  padding: 20px;
  justify-content: space-around;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}

.dashboard_main_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  width: 130px;
  height: fit-content;
  cursor: pointer;
  margin: 0 auto;
}

.dashboard_main_box .title {
  text-align: center;
  font-size: 1rem;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-weight: 600;
  color: #6d6687;
}
