.preloader_container {
  height: 100vh;
  width: 100%;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 150px;
  height: 140px;
  display: flex;
  justify-content: center;
  margin: auto; */
}

.preloader:before,
.preloader:after,
.preloader {
  border-style: solid;
  border-width: 50px 40px 0;
  border-radius: 40px;
  transform-origin: bottom center;
  animation-name: rotate;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  border-color: transparent;
}

.preloader {
  width: 0;
  height: 0;
  border-top-color: #2e2a5a;
  position: relative;
  animation-duration: 1s;
}
.preloader:before,
.preloader:after {
  content: "";
  display: block;
  position: absolute;
  top: -50px;
  left: -40px;
}
.preloader:after {
  border-top-color: var(--secondary-color);
  animation-duration: 2s;
}
.preloader:before {
  border-top-color: #fff;
  animation-duration: 4s;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* .preloader_container {
  height: 100vh;
  width: 100%;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.preloader_container section {
  perspective: 200px;
}

.preloader {
  width: 80px;
  height: 80px;
  background: #fff;
  border-radius: 10px;
  transition: all 5s;
  animation: loader 1.2s linear infinite;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotateX(0deg) rotateY(180deg);
  }
  100% {
    transform: rotateX(180deg) rotateY(180deg);
  }
} */
