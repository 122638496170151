.transaction_table {
  margin: 20px auto;
  max-width: 1110px;
  background-color: #fff;
  padding: 4px;
  padding-top: 10px;
  box-shadow: 1px 0 10px lightgray;
}
.transaction_backdrop {
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 100%;
  background-color: transparent;
  z-index: 100;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.transaction_backdrop_active {
  height: 100vh;
}
.transaction_table_topbar {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.transaction_table_topbar_item {
  display: flex;
  align-items: center;
  margin: 0 5px;
}

.transaction_table_topbar_item p {
  margin: 0;
  font-size: 0.9rem;
}
.transaction_table_topbar_item p ~ span {
  margin: 3px;
  font-weight: bold;
  color: var(--primary-color);
  font-size: 1rem;
}

.transaction_table_topbar .custom-column {
  position: relative;
}
.transaction_table_topbar .custom-column .custom-column-box {
  position: absolute;
  left: 1px;
  top: 101%;
  background-color: #fff;
  width: 100px;
  height: 0;
  padding: 0;
  overflow: hidden;
  transition: 150ms;
}

.transaction_table_topbar .custom-column .custom-column-box_active {
  min-width: 200px;
  height: fit-content;
  width: fit-content;
  padding: 10px;
  box-shadow: 1px -1px 10px rgba(128, 128, 128, 0.596);
  padding-right: 20px;
  z-index: 200;
}

.transaction_table_topbar .custom-column .custom-column-box div {
  display: flex;
  align-items: center;
}
.transaction_table_topbar .custom-column .custom-column-box div p {
  /* white-space: nowrap; */
  width: fit-content;
}

.transaction_table_navbar {
  padding: 10px 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.transaction_table_navbar .select_count {
  padding: 3px 6px;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  font-weight: bold;
  margin-right: 10px;
  margin-left: 12px;
}

.transaction_table_navbar .search {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  box-shadow: 1px 1px 10px rgba(211, 211, 211, 0.685);
  width: fit-content;
}

.transaction_table_navbar .search input {
  width: 200px;
  border: none;
  outline: none;
  border-right: 1px solid var(--primary-color);
}
.transaction_table_navbar .table_navbar_item {
  margin: 0 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.transaction_table_navbar .table_navbar_item p {
  margin: 0;
  margin-left: 5px;
}

@media (max-width: 500px) {
  .transaction_table_navbar .table_navbar_item {
    margin: 0 5px;
  }
  .transaction_table_navbar .table_navbar_item {
    height: 33px;
    width: 33px;
    overflow: hidden;
  }

  .transaction_table_navbar .select_count {
    margin: 0 4px;
  }
  .transaction_table_navbar .search input {
    width: 160px;
  }
}
