.transaction_head_container,
.transaction_head {
  background-color: var(--secondary-color);
  position: relative;
}
.transaction_head_backdrop {
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 100%;
  background-color: transparent;
  z-index: 100;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.transaction_head_backdrop_active {
  height: 86vh;
}
.transaction_head {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.transaction_head_left h1 {
  margin: 0;
  margin-top: 10px;
  font-size: 30px;
}

.transaction_head_right {
  display: flex;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  position: relative;
  padding: 3px 20px;
  min-width: 160px;
}

.transaction_head_right h3 {
  margin: 0;
}
.transaction_head_right p {
  margin: 0;
}

.transaction_head_calendar {
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: 200;
  overflow: hidden;
  height: 0;
}
.transaction_head_calendar_active {
  height: fit-content;
}

@media (max-width: 600px) {
  .transaction_head_right {
    margin-right: 5px;
  }
}
