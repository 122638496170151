.dashboard_sidebar-left_container,
.dashboard_sidebar-right_container {
  min-width: 250px;
  min-height: 400px;
  height: fit-content;
  margin: 10px;
}
.dashboard_sidebar-left,
.dashboard_sidebar-right {
  position: fixed;
}

.dashboard_sidebar-left_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard_sidebar-left_box {
  border-radius: 5px;
  width: fit-content;
  width: 250px;
  padding: 20px 10px;
  background-color: #fff;
  box-shadow: 1px -1px 12px lightgray;
  margin: 10px;
  text-align: center;
}
.dashboard_sidebar-left_box .title {
  color: #7e5ea7;
  font-weight: bolder;
  font-size: 1.8rem;
  margin-bottom: 5px;
}
.dashboard_sidebar-left_box .sub-title {
  min-width: 180px;
  font-weight: 200;
  width: 100%;
  padding: 0;
}

.dashboard_sidebar-right_container {
  min-width: 290px;
}

.dashboard_sidebar-right_box {
  background-color: #7e5ea7;
  padding: 30px 25px;
  border-radius: 5px;
  margin: 10px;
}

.dashboard_sidebar-right_box_item {
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 8px;
  align-items: center;
}
.dashboard_sidebar-right_box_item label {
  margin: 7px 0;
  color: #fff;
  font-size: 1rem;
  white-space: nowrap;
  font-weight: 200;
}
.dashboard_sidebar-right_box_item p {
  justify-self: flex-end;
  font-weight: bold;
  margin: 0;
  color: #fff;
}
.dashboard_sidebar-right_box button {
  background-color: #fec66c;
  color: #fff;
}
.dashboard_sidebar-right_box button:hover {
  background-color: #2e2a5a;
  color: #fec66c;
}

@media (max-width: 800px) {
  .dashboard_sidebar-left_container,
  .dashboard_sidebar-right_container {
    min-height: 150px;
  }
  .dashboard_sidebar-left,
  .dashboard_sidebar-right {
    position: unset;
    min-height: 150px;
  }
}
