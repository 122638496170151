:root {
  --font-1: #6c757d;
  --font-2: #5a5e61;
}
.login,
.signup,
.e-login,
.e-signup {
  padding: 20px 40px;
  background-color: #fff;
  text-align: center;
  margin: 10px;
}
.login_head,
.signup_head {
  font-size: 2.3rem;
  color: #44415a;
  text-align: center;
  margin-bottom: 10px;
}
.login_sub-head,
.signup_sub-head {
  color: var(--font-1);
  font-size: 1rem;
  margin-bottom: 20px;
  text-transform: capitalize;
  letter-spacing: 1px;
}
.login_form-elem,
.signup_form-elem {
  display: flex;
  flex-direction: column;
}
.login_form-elem label,
.signup_form-elem label {
  text-align: start;
  color: var(--font-1);
  font-size: 0.9rem;
  letter-spacing: 1px;
  font-weight: 500;
  margin-top: 10px;
  padding: 0 15px;
}
.login_form-elem input,
.signup_form-elem input {
  background: #fff;
  min-width: 250px;
  border: none;
  outline: none;
  padding: 15px;
  box-shadow: -1px 2px 5px rgb(0 0 0 / 10%);
  border-radius: 5px;
  font-size: 1rem;
  color: var(--font-2);
}
.signup_form-elem .password-input,
.login_form-elem .password-input {
  box-shadow: none;
}
.login_form-elem input:focus,
.signup_form-elem input:focus {
  box-shadow: -2px 7px 12px rgb(0 0 0 / 20%);
}
.signup_form-elem .password-input:focus,
.login_form-elem .password-input:focus {
  box-shadow: none;
}

.login_form-elem input::placeholder,
.signup_form-elem input::placeholder {
  color: #8f9397;
}
.login_form-elem select,
.signup_form-elem select {
  background: #fff;
  min-width: 250px;
  border: none;
  outline: none;
  padding: 15px;
  box-shadow: -1px 2px 5px rgb(0 0 0 / 10%);
  border-radius: 5px;
  font-size: 1rem;
  color: var(--font-2);
}
.login_form-elem select:focus,
.signup_form-elem select:focus {
  box-shadow: -2px 7px 12px rgb(0 0 0 / 20%);
}

.login_special-text,
.signup_special-text {
  cursor: pointer;
  transition: 200ms;
  color: #7e5ea7;
}

.login_special-text:hover,
.signup_special-text:hover {
  color: #241e34;
  transform: scale(1.2);
}

.e-signup_container {
  align-items: center;
}
.e-signup_text-content {
  max-width: 600px;
  padding: 0 10px;
  color: #fff;
}
.e-signup_text-content h1 {
  text-align: center;
  font-size: 2.3rem;
}
.e-signup_text-content ul {
  padding-left: 20px;
}
.e-signup_text-content ul li {
  list-style-type: square;
  font-size: 1rem;
  margin: 10px;
}
.signup_pass-box {
  position: absolute;
  left: 0;
  top: 103%;
  background-color: #fff;
  width: 100%;
  height: 0;
  padding: 0;
  overflow: hidden;
  transition: 150ms;
  text-align: center;
}

.signup_pass-box_active {
  min-width: 150px;
  height: fit-content;
  padding: 10px;
  box-shadow: 1px -1px 10px rgba(0 0 0 /14%);
  padding-right: 20px;
  z-index: 100;
}
.signup_pass-box li {
  display: flex;
}
.signup_pass-box p {
  margin: 0 5px;
}

.field-error-msg {
  font-size: 0.75rem;
  font-weight: bold;
  color: #ff4500;
  text-align: start;
  margin-left: 10px;
  width: 100%;
}

@media (max-width: 700px) {
  .e-signup_container {
    flex-direction: column;
  }
}
