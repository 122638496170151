.dashboard_body {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 800px) {
  .dashboard_body {
    flex-direction: column;
    justify-content: center;
  }
}
