.auth {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background: #2e2a5a;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo{
  position: absolute;
  top: 10px;
  left: 30px;
}
@media screen and (max-width: 992px) {
  .ilustration, .logo{
    display: none;
  }
}