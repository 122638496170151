.navbar_container {
  width: 100%;
  height: 84px;
  background-color: #2e2a5a;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  position: relative;
}
.navbar {
  background-color: #2e2a5a;
  width: 100%;
  position: fixed;
  z-index: 800;
  padding: 0;
}

.navbar_backdrop {
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.navbar_backdrop_active {
  z-index: 100;
  height: 100vh;
}

.navbar_inner {
  margin: 0 auto;
  max-width: 1140px;
  min-height: 84px;
  width: 100%;
  display: flex;
  padding: 0px 20px;
  justify-content: space-between;
  align-items: center;
}
.navbar_logo {
  display: flex;
  align-items: baseline;
}

.navbar_logo img {
  max-height: 60px;
  height: 100%;
}

.navbar_user {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  color: #fff;
  padding: 10px;
}
.navbar_user_body {
  display: flex;
  cursor: pointer;
  align-items: center;
}
.navbar_user p {
  margin: 0;
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: capitalize;
  color: #fff;
  text-align: center;
}
.navbar_user p span {
  font-size: 0.9rem;
}
.navbar_user small {
  font-size: 0.8rem;
  font-weight: bold;
  color: lightgray;
}
.navbar_box {
  position: absolute;
  right: 0;
  top: 105%;
  background-color: #fff;
  color: #000;
  box-shadow: 1px -1px 10px rgba(128, 128, 128, 0.596);
  height: 0;
  width: 30%;
  overflow: hidden;
  transition: 100ms;
  z-index: 2000;
}
.navbar_box_active {
  min-width: 150px;
  height: fit-content;
  width: fit-content;
  padding: 10px 20px;
}

.navbar_box ul li {
  list-style: none;
  margin: 10px;
  cursor: pointer;
}

@media (max-width: 500px) {
  .navbar_logo {
    font-size: 1.3rem;
  }
  .navbar_logo img {
    max-height: 50px;
  }
}
